<template>
  <div id="StopBase">
    <div class="content">
      <h3 class="title">停运基站列表</h3>
      <div class="search-criteria">
        <a-space :size="size">
          <div class="form-some">
            <div class="large-title">企业或基站名称</div>
            <a-input v-model="JZMC" style="width: 140px" placeholder="请输入如企业名称" />
          </div>
          <div class="form-some">
            <div class="large-title">时间段:</div>
            <a-range-picker
              style="width: 280px"
              :default-value="[
                moment($store.state.TYJZKSSJ),
                moment($store.state.TYJZJSSJ),
              ]"
              show-time
              :size="size"
              :format="dateFormat"
              @change="onChangeDate"
            />
          </div>
          <a-button
            style="margin-left: 20px"
            :size="size"
            type="primary"
            @click="onSubmit"
          >
            确定
          </a-button>
          <a-button
            style="margin-left: 20px"
            @click="onExport"
            :size="size"
            type="primary"
          >
            导出
          </a-button>
          <router-link v-if="LX == 2 || LX == 3" to="/main/addbase/0">
            <a-button style="margin-left: 20px" :size="size" type="primary">
              添加停运基站
            </a-button>
          </router-link>
        </a-space>
      </div>
      <div class="table">
        <a-table
          :scroll="{ y: 530 }"
          :customRow="customRow"
          size="small"
          :loading="loading"
          :row-key="(r, i) => i.toString()"
          bordered
          :pagination="false"
          :columns="columns"
          :data-source="data"
        >
          <div slot="TYBB" slot-scope="TYBB">
            <span v-if="TYBB == 1">有</span>
            <span v-else>无</span>
          </div>
          <span slot="operating" slot-scope="text, record">
            <a-button type="primary" @click="onDelete(record)"> 删除 </a-button>
          </span>
        </a-table>
        <div style="display: flex; padding: 10px">
          <div style="flex: 1"></div>
          <a-pagination
            show-quick-jumper
            :page-size="pageSize"
            v-model="current"
            :total="total"
            :show-total="
              (total, range) => `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`
            "
            @change="onChange"
          />
        </div>
      </div>
    </div>
    <!-- 表格弹框 -->
    <a-modal
      v-model="mapTableDialog"
      centered
      :footer="null"
      :width="modalWidth"
      wrapClassName="map-dialog-wrapper"
    >
      <app-table v-bind:reportData="reportData"> </app-table>
    </a-modal>
  </div>
</template>

<script>
import { http } from "../../utils/Http.js";
import moment from "moment";
import AppTable from "../../components/often/AppTable.vue";

const columns = [
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    width: 240,
    align: "center",
    ellipsis: true,
  },
  {
    title: "基站名称",
    dataIndex: "station_name",
    align: "center",
  },
  {
    title: "停运时段",
    dataIndex: "TYSD",
    width: 240,
    align: "center",
  },
  {
    title: "停运原因",
    dataIndex: "TYYY",
    align: "center",
  },
  {
    title: "停运报告",
    dataIndex: "TYBB",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "TYBB" },
    // customRender: (text, record, index) => {
    // console.log(text);
    // console.log('aaaaaaaaaa');
    // (1:最大值 2：最小值)
    // let name = "";

    // if (text == 1) {
    //   name = "有";
    // } else {
    //   name = "无";
    // }
    // return name;
    // },
  },
];

export default {
  data() {
    return {
      LX: this.$store.state.loggedIn.LX,

      size: "default",
      dateFormat: "YYYY-MM-DD",
      JZMC: this.$store.state.JZMC,
      KSSJ: this.$store.state.TYJZKSSJ,
      JSSJ: this.$store.state.TYJZJSSJ,
      current: 1,
      pageSize: 20,
      total: 0,
      columns,
      data: [],
      loading: false,

      modalWidth: 1130,
      mapTableDialog: false,
      reportData: {
        title: "停运报告",
        data: [],
        columns: [
          {
            title: "企业名称",
            align: "center",
            ellipsis: true,
            width: 260,
            dataIndex: "EnterpriseMC",
          },
          {
            title: "停运时段",
            dataIndex: "TYSD",
            width: 240,
            align: "center",
          },
          {
            title: "停运原因",
            ellipsis: true,
            align: "center",
            dataIndex: "TYYY",
          },
          {
            title: "基站类型",
            align: "center",
            dataIndex: "station_Type",
            // customRender: (text, record, index) => {
            //   console.log(text);
            //   let name = "";
            //   if (text == 1) {
            //     name = "废气";
            //   } else if (text == 2) {
            //     name = "废水";
            //   } else if (text == 3) {
            //     name = "扬尘";
            //   } else if (text == 4) {
            //     name = "VOCs";
            //   }
            //   return name;
            // },
          },
          {
            title: "停运报告",
            align: "center",
            dataIndex: "TYBB",
            customRender: (text, record, index) => {
              console.log(text);
              let name = "";
              if (text == 1) {
                name = "有";
              } else {
                name = "无";
              }
              return name;
            },
          },
          {
            title: "电话",
            align: "center",
            dataIndex: "phone",
          },
          {
            title: "附件",
            align: "center",
            ellipsis: true,
            dataIndex: "fileName",
            scopedSlots: { customRender: "fileName" },
          },
          // {
          //   title: "附件地址",
          //   align: "center",
          //   dataIndex: "fileadrs",
          //   scopedSlots: { customRender: "fileadrs" },
          // },
        ],
      },
    };
  },

  components: {
    AppTable,
  },

  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`

    if (to.name == "Addbase") {
      this.$store.commit("setJZMC", this.JZMC);
      this.$store.commit("setTYJZKSSJ", this.KSSJ);
      this.$store.commit("setTYJZJSSJ", this.JSSJ);
    }
    next();
  },

  created() {
    var me = this;
    this.HJJC_STATIONLIST_OUTAGE_LIST();

    // if (this.LX == 2 || this.LX == 3) {
    //   this.columns.push({
    //     title: "操作",
    //     align: "center",
    //     width: 120,
    //     scopedSlots: { customRender: "operating" },
    //   });
    // }

    // this.HJJC_XXTS_PC();
  },

  mounted() {},

  methods: {
    moment,

    //导出
    onExport() {
      let me = this;
      // let ID = me.$store.state.loggedIn.CKM + ',,,,' + this.JZMC + ',,' + this.KSSJ + ',' + this.JZMC
      let ID = `${me.$store.state.loggedIn.CKM},,,,${this.JZMC},,${me.KSSJ},${me.JSSJ}`;

      let HTTPURL = this.$store.state.HTTPURL;
      console.log(HTTPURL);

      window.open(
        "" +
          HTTPURL +
          "/data/RPT_GetPrintExcelFile.aspx?Module=SP&UID=admin&PID=&Template=104C4B0D23F6410B91A2764DHJJCTYLB&ID=" +
          ID +
          ""
      );
    },

    showDrawer() {
      this.visible = true;
    },

    showDrawerHide() {
      this.visible = false;
    },

    onClose() {
      this.visible = false;
    },

    //选择日期
    onChangeDate(date, dateString) {
      this.KSSJ = dateString[0];
      this.JSSJ = dateString[1];
    },

    //分页
    onChange(page, pageSize) {
      this.current = page;
      this.HJJC_STATIONLIST_OUTAGE_LIST();
    },

    //按钮-确定
    onSubmit() {
      this.current = 1;
      console.log(this.current);
      this.HJJC_STATIONLIST_OUTAGE_LIST();
    },

    HJJC_STATIONLIST_OUTAGE_LIST() {
      var me = this;
      me.loading = true;
      var params = {
        CKM: this.$store.state.loggedIn.CKM,
        SFBH: "",
        SBH: "",
        XBH: "",
        JZMC: this.JZMC,
        SXZM: "",
        KSSJ: me.KSSJ,
        JSSJ: me.JSSJ,
        // start: (me.current - 1) * me.pageSize,
        // limit: me.pageSize,
        PAGESIZE: me.pageSize,
        PAGENUMBER: me.current,
      };
      http({
        url: "/data/q30/HJJC_STATIONLIST_OUTAGE_LIST.aspx",
        data: params,
        success(obj) {
          console.log(obj);
          me.data = obj.Data;
          me.loading = false;
          me.total = obj.total;
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },

    // 基站表格点击事件
    customRow(record, index) {
      var me = this;
      return {
        on: {
          // 鼠标单击行
          click: (event) => {
            me.$router.push(`/main/addbase/${record.TYID}`);
          },
        },
      };
    },

    //表格行点击事件
    onClick(record) {
      console.log(record);
      let data = [];
      // record.file_name = "1|||2|||3|||4|||5";
      // record.file_adrs = "a|||b|||d|||e";

      // record.file_name = "asdfasd";
      // record.file_adrs = "aetree";

      //将这条数据组织出来
      if (record.file_name) {
        if (record.file_name.indexOf("|||") != -1) {
          let file_nameArr = record.file_name.split("|||");
          let file_adrsArr = record.file_adrs.split("|||");
          for (var i = 0; i < file_nameArr.length; i++) {
            let fileName = "";
            if (file_nameArr[i]) {
              fileName = file_nameArr[i];
            }

            let fileadrs = "";
            if (file_adrsArr[i]) {
              fileadrs = file_adrsArr[i];
            }

            data.push({
              EnterpriseMC: record.EnterpriseMC,
              TYSD: record.TYSD,
              TYYY: record.TYYY,
              station_Type: record.station_Type,
              TYBB: record.TYBB,
              phone: record.phone,
              fileName: fileName,
              fileadrs: fileadrs,
            });
          }
        } else {
          data.push({
            EnterpriseMC: record.EnterpriseMC,
            TYSD: record.TYSD,
            TYYY: record.TYYY,
            station_Type: record.station_Type,
            TYBB: record.TYBB,
            phone: record.phone,
            fileName: record.file_name,
            fileadrs: record.file_adrs,
          });
        }
      } else {
        data.push({
          EnterpriseMC: record.EnterpriseMC,
          TYSD: record.TYSD,
          TYYY: record.TYYY,
          station_Type: record.station_Type,
          TYBB: record.TYBB,
          phone: record.phone,
          fileName: record.file_name,
          fileadrs: record.file_adrs,
        });
      }
      // data = [
      //   {
      //     fileName: "百度1",
      //     fileadrs:
      //       "http://ftp.allspark.top/80/05/0E/2E/80050E2E9ADC4B228B1CC3442F2E57D8.doc",
      //   },
      //   {
      //     fileName: "百度2",
      //     fileadrs:
      //       "http://ftp.allspark.top/80/05/0E/2E/80050E2E9ADC4B228B1CC3442F2E57D8.doc",
      //   },
      //   {
      //     fileName: "百度3",
      //     fileadrs:
      //       "http://ftp.allspark.top/80/05/0E/2E/80050E2E9ADC4B228B1CC3442F2E57D8.doc",
      //   },
      //   {
      //     fileName: "百度4",
      //     fileadrs:
      //       "http://ftp.allspark.top/80/05/0E/2E/80050E2E9ADC4B228B1CC3442F2E57D8.doc",
      //   },
      //   {
      //     fileName: "百度5",
      //     fileadrs:
      //       "http://ftp.allspark.top/80/05/0E/2E/80050E2E9ADC4B228B1CC3442F2E57D8.doc",
      //   },
      // ];
      this.reportData.data = data;
      this.mapTableDialog = true;
    },

    onDelete(record) {
      this.info(record);
    },

    info(record) {
      let me = this;
      const h = this.$createElement;
      this.$confirm({
        title: "提示",
        centered: true,
        content: h("div", {}, [h("p", "您确定要删除这条信息么？")]),
        onOk() {
          me.HJJC_STATION_OUTAGE_DELETE(record);
        },
        onCancel() {
          console.log("取消");
        },
      });
    },

    HJJC_STATION_OUTAGE_DELETE(record) {
      // console.log(record);
      let me = this;
      http({
        url: "/data/q30/HJJC_STATION_OUTAGE_DELETE.aspx",
        data: {
          CKM: this.$store.state.loggedIn.CKM,
          TYID: record.TYID,
        },
        success(obj) {
          console.log(obj);
          me.HJJC_STATIONLIST_OUTAGE_LIST();
          me.success(record, "删除成功!");
        },
        failure(obj) {},
        me,
      });
    },

    success(record, messages) {
      // //获取元素在数组的下标
      // Array.prototype.indexOf = function (val) {
      //   for (var i = 0; i < this.length; i++) {
      //     if (this[i] == val) {
      //       return i;
      //     }
      //   }
      //   return -1;
      // };

      // //根据数组的下标，删除该下标的元素
      // Array.prototype.remove = function (val) {
      //   var index = this.indexOf(val);
      //   if (index > -1) {
      //     this.splice(index, 1);
      //   }
      // };

      // this.reportData.data.remove(record);

      const h = this.$createElement;
      this.$success({
        title: "提示",
        centered: true,
        content: h("div", {}, [h("p", messages)]),
      });
    },
  },

  destoryed() {
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
#StopBase {
  color: #181818;
  width: 1200px;
  height: 100%;
  padding-top: 20px;
  margin: 0 auto;

  .content {
    background-color: #ffffff;
    padding: 20px 20px 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 24px;
    }

    .search-criteria {
      .form-some {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .large-title {
          margin-right: 10px;
        }
      }
    }

    .table {
      flex: 1;
      padding-top: 24px;
      display: flex;
      flex-direction: column;

      .ant-table-wrapper {
        flex: 1;
      }

      @import "../../assets/css/AppHome.scss";
    }
  }

  &::v-deep .ant-btn-primary {
    background-color: #1876ff;
    border-color: #1876ff;
  }

  &::v-deep .ant-table-wrapper {
    .ant-table-row {
      cursor: pointer;
    }
  }
}

// //抽屉样式
// .alarm-list {
// 	position: relative;

// 	.alarm-one {
// 		height: 135px;
// 		width: 336px;
// 		background: url('../../assets/img/pics/019.png') no-repeat center center;
// 		background-size: 100% 100%;
// 		margin-bottom: 20px;

// 		.header {
// 			height: 40px;
// 			line-height: 40px;
// 			position: relative;
// 			padding-bottom: 3px;
// 			margin-bottom: 12px;

// 			img {
// 				width: 48px;
// 				height: 40px;
// 				position: absolute;
// 				left: 0;
// 				top: 0;
// 			}

// 			.title {
// 				font-size: 14px;
// 				font-family: PingFangSC-Medium, PingFang SC;
// 				font-weight: 500;
// 				padding: 0 0 0 46px;
// 				font-weight: 700;
// 				padding-right: 20px;
// 				height: 40px;
// 				text-align: center;
// 				overflow: hidden;
// 				text-overflow: ellipsis;
// 				white-space: nowrap;
// 			}

// 			.anticon {
// 				position: absolute;
// 				right: 5px;
// 				top: 5px;
// 				cursor: pointer;
// 			}
// 		}

// 		.text {
// 			font-size: 12px;
// 			font-family: PingFangSC-Regular, PingFang SC;
// 			font-weight: 400;
// 			color: #181818;
// 			line-height: 17px;
// 			padding: 0 12px 9px;
// 			margin-bottom: 10px;

// 		}

// 		.date {
// 			height: 17px;
// 			line-height: 17px;
// 			font-size: 12px;
// 			padding: 0 12px;
// 			font-family: PingFangSC-Regular, PingFang SC;
// 			font-weight: 400;
// 			color: #515151;
// 		}

// 	}
// }

// //图钉样式
// .alarm-button {
// 	width: 40px;
// 	height: 160px;
// 	background: url(../../assets/img/pics/023.png);
// 	font-size: 16px;
// 	font-family: PingFangSC-Medium, PingFang SC;
// 	font-weight: 500;
// 	color: #FFFFFF;
// 	line-height: 22px;
// 	text-align: center;
// 	padding-top: 23px;
// 	cursor: pointer;
// }
</style>
<!-- 
<style>
	/* .ant-drawer-body {
		padding: 24px 16px;
		background-color: #AECDFB;
	} */

	.ant-drawer-wrapper-body {
		position: relative;
	}

	.alarm-button-position {
		position: absolute;
		right: -17px;
		top: 4.1rem;
	}

	.ant-drawer-wrapper-body::-webkit-scrollbar {
		width: 6px;
		background-color: #78AFFF;
	}

	.ant-drawer-wrapper-body::-webkit-scrollbar-thumb {
		width: 6px;
		height: 20px;
		border-radius: 3px;
		background-color: #FFF;
	}
</style>
 -->
<style lang="scss">
@import "../map/MapBase.scss";
</style>
