<template>
  <div id="MapTable">
    <div class="title">
      <div style="width: 18px"></div>
      <p>{{ table.title }}</p>
    </div>
    <div class="table-entity">
      <div class="table-shell">
        <a-table
          class="full-table"
          :loading="loading"
          :columns="table.columns"
          bordered
          size="small"
          :row-key="
            (r, i) => {
              return i.toString();
            }
          "
          :data-source="table.data"
          :pagination="false"
        >
          <span slot="fileName" slot-scope="text, record">
            <a target="_blank" :href="record.fileadrs">{{ text }}</a>
          </span>
        </a-table>
      </div>
      <div style="display: flex; padding: 10px 20px" v-if="table.url">
        <div style="flex: 1"></div>
        <a-pagination
          :page-size="pageSize"
          v-model="current"
          :total="total"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { http } from "@/utils/Http.js";

export default {
  props: {
    reportData: Object,
  },
  data() {
    return {
      data: [],
      table: {},
      loading: false,

      //分页
      current: 1,
      pageSize: 10,
      total: 0,

      url: null,
      params: null,
    };
  },
  created() {
    this.table = this.reportData;
  },
  watch: {
    reportData(value) {
      console.log(value);
      this.current = 1;
      this.table = this.reportData;
    },
  },
  methods: {
    //分页
    onChange(page, pageSize) {
      this.current = page;
      console.log(this.reportData);

      this.params.PAGESIZE = this.pageSize;
      this.params.PAGENUMBER = this.current;

      console.log(this.params);
      this.getData(this.url, this.params);
    },

    getData(url, params) {
      var me = this;
      this.loading = true;
      http({
        url: url,
        data: params,
        success(obj) {
          console.log(obj);

          me.loading = false;

          me.data = obj.Data;
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#MapTable {
  min-height: 332px;
  padding: 20px 30px 10px;
  background: rgba(255, 255, 255, 0.9);

  .title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 22px;
    color: #333333;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
      text-align: center;
      flex: 1;
      margin: 0;
    }

    img {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  .nav-title {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 22px;
    color: #333333;
    display: flex;
    align-items: center;
    padding-left: 25px;
  }

  // .table-entity {
  //   .table-shell {
  //     &::v-deep .ant-table-wrapper {
  //       .ant-table-content {
  //         border: 0;
  //       }
  //       .ant-table {
  //         border: 0;
  //       }

  //       table {
  //         border: 0;

  //         .ant-table-thead {
  //           tr {
  //             border: 0;
  //             th {
  //               border: 0;
  //               font-size: 12px;
  //               padding: 11px 5px;
  //             }
  //           }
  //         }
  //         tr {
  //           border: 0;
  //           td {
  //             border: 0;
  //             padding: 7px 5px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
</style>
